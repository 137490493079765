import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Auth, Storage } from "aws-amplify";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";

/**
 *
 *
 *  TODO CLEAN UP CODE AND ADD COMMENTS
 *
 *
 *
 *
 *
 *
 */

const ApiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 30000,
});

export interface User {
  family_name: string;
  given_name: string;
  email: string;
  picture: string;
  phone_number: number | undefined;
  location: string;
  organization: string;
  job_title: string;
}

export interface LoginState {
  jwtToken: string;
  currentUser?: User;
  userFetched?: boolean;
  userError?: Error | string | null;
  userInfo?: any;
  userInfoLoading?: boolean;
  errorUserInfo?: Error | string | null;
}

const initialState: LoginState = {
  jwtToken: "",
  currentUser: {
    family_name: "",
    given_name: "",
    email: "",
    picture: "",
    phone_number: undefined,
    location: "",
    organization: "",
    job_title: "",
  },
  userFetched: false,
  userError: null,
  userInfo: {},
  userInfoLoading: false,
  errorUserInfo: null,
};

export const fetchJwtToken = createAsyncThunk(
  "login/fetchJwtToken",
  async () => {
    try {
      const session = await Auth.currentSession();
      const jwtToken: string = session.getAccessToken().getJwtToken();
      return jwtToken;
    } catch (error) {
      console.log("fetchJwtToken error", error);
      // return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * createAsyncThink<User>
 * <User> is the response type.
 * This thunk does not have any input parameters
 *
 * THIS IS WHY WE HAVE A NESTED TRY-CATCH AND DONT HANDLE THE ERRORS WHEN FETCHING USER PICTURE
 *  A 404 means the user doesn't have a picture in s3.
 *  We do not need to do anything in that case because user.picture will just be set to an empty string.
 *  If it's any other error code, technically we should reject it,
 *  but if we were to reject it then the entire thunk would be rejected and we don't want that to happen simply
 *  because of an error related to the picture.
 * (Maybe in the future we should think about fetching the user picture in it's own reducer).
 */
export const fetchCurrentUser = createAsyncThunk<User>(
  "login/fetchCurrentUser",
  async (args_, thunkAPI) => {
    try {
      const res = await Auth.currentUserInfo();

      if (res && res.attributes) {
        let organization = res.attributes["custom:organization"];
        let job_title = res.attributes["custom:job_title"];
        const s3Url = await Storage.get("profilePicture.png");

        let image = "";
        if (s3Url) {
          try {
            const { data } = await axios.get(s3Url);
            image = data;
          } catch (error: any) {
            if (!(error.status === 404)) {
              console.error("profile picture error", error);
            }
          }
        }

        return { ...res.attributes, organization, job_title, picture: image };
      }
    } catch (error) {
      console.log("fetchCurrentUser error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  "login/fetchUserInfo",
  async (args, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const jwtToken = state.loginDetails.jwtToken;

      if (!jwtToken) {
        throw new Error("JWT Token is missing");
      }
      const res = await ApiClient.get("/user/info", {
        headers: {
          Authorization: jwtToken,
        },
      }); 
      const data = await res.data;
      return data;
    } catch (error) {
      console.error("Error fetching user modules:", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// SLICE;
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateLoginDetails: (state, action: PayloadAction<LoginState>) => {
      console.log("Updating login slice...");
      state.jwtToken = action.payload.jwtToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJwtToken.pending, (state, action) => {
        console.log("fetchJwtToken.pending", action);
      })
      .addCase(fetchJwtToken.fulfilled, (state, action) => {
        console.log("fetchJwtToken.fulfilled", action);
        state.jwtToken = action.payload!;
      })
      .addCase(fetchJwtToken.rejected, (state, action) => {
        console.log("fetchJwtToken.rejected", action);
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        console.log("fetchCurrentUser.fulfilled", action.payload);
        state.currentUser = action.payload;
        state.userFetched = !!action.payload;
      })
      .addCase(fetchCurrentUser.pending, (state, action) => {
        console.log("fetchCurrentUser.pending", action);
        state.userFetched = false;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        console.log("fetchCurrentUser.rejected", action);
        state.userError = action.error.message;
        state.userFetched = false;
      })
      .addCase(fetchUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        console.log("fetchUserInfo.fulfilled", action);
        state.userInfo = action.payload;
        state.userInfoLoading = false;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        console.log(
          "ERROR fetchUserInfo.rejected",
          action
        );
        state.userInfoLoading = false;
        state.errorUserInfo = action.error.message;
      });
  },
});

// ACTION CREATORS
export const { updateLoginDetails } = loginSlice.actions;

// SELECTORS
export const selectJwtToken = (state: RootState) => state.loginDetails.jwtToken;
export const selectCurrentUser = (state: RootState) =>
  state.loginDetails.currentUser;
export const selectUserPicture = (state: RootState) =>
  state.loginDetails.currentUser?.picture;
export const selectIsUserFetched = (state: RootState) =>
  state.loginDetails.userFetched;
export const selectUserInfo = (state: RootState) => state.loginDetails.userInfo;

export const selectUserInfoLoading = (state: RootState) => state.loginDetails.userInfoLoading;

// SLICE REDUCER
export const loginReducer = loginSlice.reducer;